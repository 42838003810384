import { useState, useEffect, useRef } from 'react'
import { demoNailsColors } from '@src/helpers/demoNailsColors'
import Nail from '@src/components/Nail/Nail'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import './NailsSelector.scss'

const NailsSelector = ({ onNailSelect, disabled }) => {
	const [selectedId, setSelectedId] = useState('')
	const colors = demoNailsColors
	const swiperRef = useRef(null)

	const handleNailClick = (item) => {
		setSelectedId(item.id)
		if (onNailSelect) onNailSelect(item)
	}

	useEffect(() => {
		if (swiperRef.current) {
			const initialSpeed = swiperRef.current.params.speed;
			const autoSlideSpeed = 400;

			swiperRef.current.params.speed = autoSlideSpeed;

			swiperRef.current.slideNext();

			setTimeout(() => {
				swiperRef.current.slidePrev();
			}, 600);

			setTimeout(() => {
				swiperRef.current.params.speed = 1200;
			}, 500);
			setTimeout(() => {
				swiperRef.current.params.speed = initialSpeed;
			}, 700);
		}
	}, []);

	return (
		<div className="NailsSelector">
			<Swiper
				onSwiper={(swiper) => (swiperRef.current = swiper)}
				spaceBetween={10}
				slidesPerView={6}
			>
				{colors.map((item, sectionIndex) => (
					<SwiperSlide key={`${item?.id}-${sectionIndex}`}>
						<Nail
							nailId={item?.id}
							mainColor={item?.main}
							glowColor={item?.glow}
							code={item?.code}
							name={item?.name}
							selectedId={selectedId}
							setSelectedId={setSelectedId}
							onClick={() => handleNailClick(item)}
							{...{disabled}}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	)
}

export default NailsSelector
