import { convertBlobToBase64 } from '@src/utility/convertBlobToBase64'
import { getBlobImageFromCanvas } from '@src/utility/camera'
import { getAPI } from '@src/utility/getAPI'

export const sendImageToServer = async (
	canvasRef,
	userToken,
	rgb,
	api,
	setIsLoading
) => {
	setIsLoading(true)
	try {
		const blob = await getBlobImageFromCanvas(canvasRef.current)
		const base64Image = await convertBlobToBase64(blob)

		const response = await fetch(getAPI([api.imageProcess]), {
			method: 'POST',
			headers: {
				Authorization: `Token ${userToken}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ image: base64Image, color: rgb }),
		})

		if (!response.ok) throw new Error(`Ошибка загрузки изображения: ${response.statusText}`)

		console.log('Изображение успешно обработано!')
	} catch (error) {
		console.error('Error sending image to server:', error)
		alert('Ошибка при обработке изображения.')
	} finally {
		setIsLoading(false)
	}
}
