// import { studioPictureHeight, studioPictureWidth } from "@src/config"

export const startVideo = (
	videoRef,
	studioCameraRef,
	setIsVideoStarted
) => {
	if (!videoRef.current) return

	const mainWidth = studioCameraRef?.current?.clientWidth
	const mainHeight = studioCameraRef?.current?.clientHeight

	const newWidth = 320

	const aspectRatio = mainHeight / mainWidth
	const newHeight = newWidth * aspectRatio

	navigator.mediaDevices.getUserMedia({
		video: {
			width: { ideal: newWidth },
			height: { ideal: newHeight },
			// aspectRatio: mainWidth / mainHeight,
			facingMode: 'environment',
		},
	})
		.then((stream) => {
			videoRef.current.srcObject = stream
			videoRef.current.play()
			setIsVideoStarted(true)
		})
		.catch((err) => {
			console.error('Error accessing webcam:', err)
			alert('Не удалось получить доступ к камере. Проверьте разрешения.')
			setIsVideoStarted(false)
		})
}
