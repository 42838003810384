import { useEffect, useRef } from 'react'
import {
	maxSocketConnectionAttempts,
	socketReconnectInterval
} from '@src/helpers/vars'

export const useWebSocket = (url, token, handleWebSocketMessage) => {
	const socketRef = useRef(null)
	const timeoutRef = useRef(null)
	const reconnectTimeoutRef = useRef(null)
	const reconnectAttemptsRef = useRef(0)
	const isConnectedRef = useRef(false)

	const maxReconnectAttempts = maxSocketConnectionAttempts
	const reconnectInterval = socketReconnectInterval

	useEffect(() => {
		const connectWebSocket = () => {
			if (reconnectAttemptsRef.current >= maxReconnectAttempts) {
				console.warn('Превышено максимальное количество попыток подключения.')
				return
			}

			console.log('Attempting to connect to WebSocket:', `${url}?token=${token}`)
			socketRef.current = new WebSocket(`${url}?token=${token}`)

			// Обработчик для успешного подключения
			socketRef.current.onopen = () => {
				console.log('WebSocket connection established')
				isConnectedRef.current = true
				clearTimeout(timeoutRef.current)
				reconnectAttemptsRef.current = 0
			}

			// Обработчик ошибок
			socketRef.current.onerror = (event) => {
				console.error('WebSocket error:', event)
				isConnectedRef.current = false
				scheduleReconnect()
			}

			// Обработчик закрытия соединения
			socketRef.current.onclose = (event) => {
				console.log('WebSocket connection closed:', event)
				isConnectedRef.current = false
				scheduleReconnect()
			}

			// Обработчик получения сообщений от сервера
			socketRef.current.onmessage = (event) => {
				console.log('Message from server:', event.data)
				if (handleWebSocketMessage) handleWebSocketMessage(event)
			}

			// Таймер на случай неудачного подключения
			timeoutRef.current = setTimeout(() => {
				console.warn('Не удалось установить соединение с сервером WebSocket.')
				isConnectedRef.current = false
			}, reconnectInterval)
		}

		// Планирование переподключения через заданный интервал
		const scheduleReconnect = () => {
			if (reconnectAttemptsRef.current >= maxReconnectAttempts) {
				console.warn('Превышено максимальное количество попыток подключения.')
				return
			}

			if (!reconnectTimeoutRef.current) {
				reconnectTimeoutRef.current = setTimeout(() => {
					reconnectAttemptsRef.current += 1
					console.log(`Попытка подключения: ${reconnectAttemptsRef.current}`)
					connectWebSocket()
					reconnectTimeoutRef.current = null
				}, reconnectInterval)
			}
		}

		// Первое подключение
		connectWebSocket()

		// Чистим ресурсы при размонтировании компонента
		return () => {
			if (socketRef.current) socketRef.current.close()
			if (timeoutRef.current) clearTimeout(timeoutRef.current)
			if (reconnectTimeoutRef.current) clearTimeout(reconnectTimeoutRef.current)
		}
	}, [
		url,
		token,
		handleWebSocketMessage,
		maxReconnectAttempts,
		reconnectInterval
	])

	return { socket: socketRef.current, isConnected: isConnectedRef.current }
}
