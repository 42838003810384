import { getBlobImageFromCanvas } from './camera'
import { convertBlobToBase64 } from './convertBlobToBase64'
import { getAPI } from './getAPI'

export const saveImage = async (
	canvasRef,
	userToken,
	clientId,
	setIsLoading,
) => {
	setIsLoading(true)
	try {
		const blob = await getBlobImageFromCanvas(canvasRef.current)
		const base64Image = await convertBlobToBase64(blob)

		const response = await fetch(
			getAPI(['clients',clientId,'designs']
		), {
			method: 'POST',
			headers: {
				Authorization: `Token ${userToken}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ photo: base64Image }),
		})

		if (!response.ok) throw new Error(`Ошибка загрузки изображения: ${response.statusText}`)

		console.log('Изображение успешно сохранено!')
	} catch (error) {
		console.error('Error sending image to server:', error)
		alert('Ошибка при обработке изображения.')
	} finally {
		setIsLoading(false)
	}
}
