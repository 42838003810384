export const captureImageFromVideo = (videoElement, canvasElement) => {
	if (!videoElement || !canvasElement) return

	const context = canvasElement.getContext('2d')
	canvasElement.width = videoElement.videoWidth
	canvasElement.height = videoElement.videoHeight
	context.drawImage(videoElement, 0, 0, videoElement.videoWidth, videoElement.videoHeight)
}

export const getBlobImageFromCanvas = (canvas) => {
	return new Promise((resolve) => {
		canvas.toBlob((blob) => resolve(blob), 'image/jpeg')
	})
}
