import { useRef, useState, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserToken } from '@src/services/auth/getUserToken'
import { defaultRGB } from '@src/helpers/vars'
import { useWebSocket } from '@src/hooks/useWebSocket'
import { captureImageFromVideo } from '@src/utility/camera'
import { startVideo } from '@src/utility/startVideo'
import { sendImageToServer } from '@src/utility/sendImageToServer'
import { api, studioWebSocketUrl } from '@src/config'
import Btn from '@src/components/Btn/Btn'
import PhotoBtn from '@src/components/PhotoBtn/PhotoBtn'
import Overlay from '@src/modules/Overlay/Overlay'
import StudioControll from '@src/components/Studio/StudioControll'
import StudioCamera from '@src/components/Studio/StudioCamera/StudioCamera'
import StudioConstructor from '@src/components/Studio/StudioConstructor'
import Loader from '@src/components/Loader/Loader'
import './Studio.scss'
import { getClientData } from '@src/utility/getClientData'
import { saveImage } from '@src/utility/saveImage'

const Studio = () => {
	const studioCameraRef = useRef(null)
	const videoRef = useRef(null)
	const canvasRef = useRef(null)
	const serverImageRef = useRef(null)
	const cameraMode = useRef('videoMode')

	const [isLoading, setIsLoading] = useState(false)
	const [isWaitingSock, setIsWaitingSock] = useState(false)
	const [isPhotoMode, setIsPhotoMode] = useState(true)
	const [isVideoStarted, setIsVideoStarted] = useState(false)
	const [modeIsSelected, setModeIsSelected] = useState(false)

	const navigate = useNavigate()

	const userToken = useMemo(() => getUserToken(), [])

	const handleWebSocketMessage = useCallback((event) => {
		console.debug({ event })
		if (!serverImageRef?.current) return

		const base64Image = event.data
		serverImageRef.current.src = base64Image
		setIsWaitingSock(false)
		cameraMode.current = 'imgMode'
	}, [])

	const { isConnected } = useWebSocket(
		studioWebSocketUrl,
		userToken,
		handleWebSocketMessage
	)

	const handleStartVideo = useCallback(() => {
		startVideo(
			videoRef,
			studioCameraRef,
			setIsVideoStarted
		)
		cameraMode.current = 'videoMode'
	}, [])

	const captureImage = useCallback(() => {
		captureImageFromVideo(videoRef.current, canvasRef.current)
		setIsPhotoMode(false)
		cameraMode.current = 'canvasMode'
	}, [])

	const handleNailSelect = useCallback((nail) => {
		console.log('Selected Nail:', nail)
		setIsWaitingSock(true)
		sendImageToServer(
			canvasRef,
			userToken,
			nail?.main || defaultRGB,
			api,
			setIsLoading
		)
	}, [userToken])

	const backLinkAction = () => {
		if (modeIsSelected) {
			setModeIsSelected(false)
			setIsPhotoMode(false)
		} else if (!isPhotoMode && !modeIsSelected) {
			setIsPhotoMode(true)
			cameraMode.current = 'videoMode'
		} else {
			navigate(-1)
		}
	}

	const getBackLinkText = () => {
		if (modeIsSelected) {
			return 'К категориям'
		} else if (!isPhotoMode && !modeIsSelected) {
			return 'Новое фото'
		} else {
			return 'На главную'
		}
	}

	const saveHandleImage = () => {
		const clientData = getClientData()

		if (!clientData || !clientData?.id) {
			alert('Для сохранения необходима авторизация клиента')
			return
		}

		const clientId = clientData?.id
		console.log({clientData});

		saveImage(
			canvasRef,
			userToken,
			clientId,
			setIsLoading
		)

	}

	// const testSize = studioCameraRef.current
	// ? `width: ${studioCameraRef.current.clientWidth} \nheight: ${studioCameraRef.current.clientHeight}`
	// : 'Размеры недоступны';

	const mainWidth = studioCameraRef?.current?.clientWidth
	const mainHeight = studioCameraRef?.current?.clientHeight

	if (mainWidth > 0 && mainHeight > 0) {
		videoRef.current.width = mainWidth;
		videoRef.current.height = mainHeight;

		// canvasRef.current.width = mainWidth;
		// canvasRef.current.height = mainHeight;

		// serverImageRef.current.width = mainWidth;
		// serverImageRef.current.height = mainHeight;
	}

	return (
		<div className="Studio">
			<StudioControll
				backLinkText={getBackLinkText()}
				{...{
					backLinkAction,
					isPhotoMode,
					setIsPhotoMode,
				}}
			>
				{cameraMode?.current === 'canvasMode' &&
					<Btn
						label="Сохранить"
						type="glass-dark"
						onClick={saveHandleImage}
						disabled={isWaitingSock}
					/>
				}
			</StudioControll>

			{!isVideoStarted &&
				<Overlay>
					<Btn
						type="light"
						label="Включить камеру"
						onClick={handleStartVideo}
					/>
				</Overlay>
			}

			{/* <Loader text={testSize} /> */}

			{!isConnected ? (
				<Loader text="Соединение с сервером…" />
			) : isLoading ? (
				<Loader text="Загрузка фотографии…" />
			) : isWaitingSock ? (
				<Loader text="Обработка фотографии…" />
			) : ''}

			<StudioCamera
				mode={cameraMode.current}
				blured={isWaitingSock}
				{...{
					studioCameraRef,
					videoRef,
					canvasRef,
					serverImageRef
				}}
			/>
			<div className="Studio__panel">
				{isPhotoMode ? (
					<PhotoBtn onClick={captureImage} />
				) : (
					<StudioConstructor
						onNailSelect={handleNailSelect}
						disabled={isWaitingSock}
						{...{
							modeIsSelected,
							setModeIsSelected,
						}}
					/>
				)}
			</div>
		</div>
	)
}

export default Studio
